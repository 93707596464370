import { tns } from 'tiny-slider'

function setup(slider) {

    const container = slider.querySelector('.story-slider__container')
    const controlsContainer = slider.querySelector('.story-slider__controls')

    tns({
        container,
        controlsContainer,
        nav: false,
        gutter: 1 // prevent image bleeding
    })
}

function init() {
    const elements = Array.from(document.querySelectorAll('.story-slider'))

    if (!elements.length) return;

    elements.forEach(setup)
}

init()
