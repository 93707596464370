(() => {
    let socialShare = document.querySelector('.share');
    if (socialShare) {
        let shareLinks = socialShare.querySelectorAll('a');
        shareLinks.forEach((link) => {
            link.addEventListener("click", (e) => {
                e.preventDefault();

                let url = link.href;

                let width = 575;
                let height = 400;

                let left = (document.documentElement.clientWidth / 2 - width / 2);
                let top = (document.documentElement.clientHeight - height) / 2;

                let opts = 'status=1,resizable=yes' +
                    ',width=' + width + ',height=' + height +
                    ',top=' + top + ',left=' + left;

                let win = window.open(url, '', opts);
                win.focus();
            });

        });

    }


})()
