import { tns } from 'tiny-slider'

function setup(slider) {

    const container = slider.querySelector('.hero-slider__container')
    const controlsContainer = slider.querySelector('.hero-slider__controls')
    const navContainer = slider.querySelector('.hero-slider__navigation')

    tns({
        container,
        controlsContainer,
        navContainer,
        autoHeight: true
    })
}

function init() {
    const elements = Array.from(document.querySelectorAll('.hero-slider'))

    if (!elements.length) return;

    elements.forEach(setup)
}

window.addEventListener('load', function () {
    init()
})
